import { ARCADE, Arcade } from "./types"

export const initialState: Arcade = {
  games: [],
  game: null,
  loading: false,
  action: "",
  conditions: {
    gameTypes: [],
    name: "",
    providerIds: [],
    sortingFields: { search_volume: "desc" },
  },
  meta: {
    pageNumber: 0,
    pageSize: 12,
    pagesCount: 0,
    rowsFound: 0,
  },
}

export default function arcade(state = initialState, action: any) {
  switch (action.type) {
    case ARCADE.LOAD: {
      return {
        ...state,
        action: action.payload.action,
        conditions: {
          ...initialState.conditions,
          ...action.payload.conditions,
        },
        loading: true,
      }
    }

    case ARCADE.LOADED: {
      return {
        ...state,
        games: action.payload.games,
        meta: {
          ...action.payload.meta,
        },
        loading: false,
      }
    }

    case ARCADE.ACTIVATE: {
      return {
        ...state,
        game: action.payload.game,
      }
    }

    case ARCADE.DEACTIVATE: {
      return {
        ...state,
        game: null,
      }
    }

    default: {
      return state
    }
  }
}
