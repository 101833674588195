import {
  ArcadeGame,
  ArcadeAsset,
  ArcadeAttribute,
} from "../../types/graphql-types"

import Provider from "./provider"

type GameLink = {
  url: string
}

export default class Game {
  id: string
  name: string
  normalisedName: string
  assets: ArcadeAsset[] | null
  attributes: ArcadeAttribute[]
  links: GameLink[]
  provider: Provider
  providerId: string
  minBet?: string
  maxBet?: string
  RTP?: string

  constructor(game: ArcadeGame) {
    this.id = game.id
    this.name = game.name || ""
    this.normalisedName = game.normalisedName || ""
    this.assets = (game.assets) as any
    this.attributes = game.attributes as ArcadeAttribute[]
    this.links = game.links as GameLink[]
    this.provider = game.provider as Provider
    this.providerId = game.providerId || ""

    this.minBet =
      game.attributes?.find(attr => attr!.attributeType == "Minimum Bet")
        ?.attributeValue || undefined
    this.maxBet =
      game.attributes?.find(attr => attr!.attributeType == "Maximum Bet")
        ?.attributeValue || undefined
    this.RTP =
      game.attributes?.find(attr => attr!.attributeType == "RTP")
        ?.attributeValue || undefined
  }
}
