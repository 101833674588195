import Game from "../../models/game"

export type Conditions = {
  gameTypes: []
  name: string | null
  providerIds: []
  sortingFields: any
}

export type Meta = {
  pageNumber?: number
  pageSize?: number
  pagesCount?: number
  rowsFound?: number
}

export interface Arcade {
  games: Game[]
  game: Game | null
  loading: boolean
  action: string
  meta: Meta
  conditions: Conditions
}

export enum ARCADE {
  ACTIVATE = "ARCADE_ACTIVATE",
  DEACTIVATE = "ARCADE_DEACTIVATE",
  LOAD = "ARCADE_LOAD",
  LOADED = "ARCADE_LOADED",
}
