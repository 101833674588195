exports.components = {
  "component---src-ui-pages-404-tsx": () => import("./../../../src/ui/pages/404.tsx" /* webpackChunkName: "component---src-ui-pages-404-tsx" */),
  "component---src-ui-pages-about-us-tsx": () => import("./../../../src/ui/pages/about-us.tsx" /* webpackChunkName: "component---src-ui-pages-about-us-tsx" */),
  "component---src-ui-pages-articles-tsx": () => import("./../../../src/ui/pages/articles.tsx" /* webpackChunkName: "component---src-ui-pages-articles-tsx" */),
  "component---src-ui-pages-bonuses-tsx": () => import("./../../../src/ui/pages/bonuses.tsx" /* webpackChunkName: "component---src-ui-pages-bonuses-tsx" */),
  "component---src-ui-pages-casinos-tsx": () => import("./../../../src/ui/pages/casinos.tsx" /* webpackChunkName: "component---src-ui-pages-casinos-tsx" */),
  "component---src-ui-pages-compare-tsx": () => import("./../../../src/ui/pages/compare.tsx" /* webpackChunkName: "component---src-ui-pages-compare-tsx" */),
  "component---src-ui-pages-free-games-tsx": () => import("./../../../src/ui/pages/free-games.tsx" /* webpackChunkName: "component---src-ui-pages-free-games-tsx" */),
  "component---src-ui-pages-index-tsx": () => import("./../../../src/ui/pages/index.tsx" /* webpackChunkName: "component---src-ui-pages-index-tsx" */),
  "component---src-ui-pages-live-casinos-tsx": () => import("./../../../src/ui/pages/live-casinos.tsx" /* webpackChunkName: "component---src-ui-pages-live-casinos-tsx" */),
  "component---src-ui-pages-payments-tsx": () => import("./../../../src/ui/pages/payments.tsx" /* webpackChunkName: "component---src-ui-pages-payments-tsx" */),
  "component---src-ui-pages-privacy-policy-tsx": () => import("./../../../src/ui/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-ui-pages-privacy-policy-tsx" */),
  "component---src-ui-pages-terms-of-service-tsx": () => import("./../../../src/ui/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-ui-pages-terms-of-service-tsx" */),
  "component---src-ui-templates-article-tsx": () => import("./../../../src/ui/templates/article.tsx" /* webpackChunkName: "component---src-ui-templates-article-tsx" */),
  "component---src-ui-templates-casino-tsx": () => import("./../../../src/ui/templates/casino.tsx" /* webpackChunkName: "component---src-ui-templates-casino-tsx" */),
  "component---src-ui-templates-game-tsx": () => import("./../../../src/ui/templates/game.tsx" /* webpackChunkName: "component---src-ui-templates-game-tsx" */),
  "component---src-ui-templates-guide-tsx": () => import("./../../../src/ui/templates/guide.tsx" /* webpackChunkName: "component---src-ui-templates-guide-tsx" */),
  "component---src-ui-templates-payment-tsx": () => import("./../../../src/ui/templates/payment.tsx" /* webpackChunkName: "component---src-ui-templates-payment-tsx" */),
  "component---src-ui-templates-profile-tsx": () => import("./../../../src/ui/templates/profile.tsx" /* webpackChunkName: "component---src-ui-templates-profile-tsx" */)
}

