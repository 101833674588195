import React from "react"
import { BONUS_POPUP } from "./types"

import Bonus from "~/models/Bonus"

export function activate(bonus: Bonus, location: number[]) {
  return {
    type: BONUS_POPUP.ACTIVATE,
    payload: { bonus, location },
  }
}

export function deactivate() {
  return {
    type: BONUS_POPUP.DEACTIVATE,
    payload: {},
  }
}

export function handleActivation(
  bonus: Bonus,
  e: React.MouseEvent<HTMLElement>
) {
  return async function (dispatch: any) {
    let location = [e.pageX, e.pageY]
    dispatch(activate(bonus, location))
  }
}

export function handleDeactivation() {
  return deactivate()
}
