import { BONUS_POPUP, BonusPopup } from "./types"

export const initialState: BonusPopup = {
  bonus: null,
  location: null,
}

export default function bonusPopup(state = initialState, action: any) {
  switch (action.type) {
    case BONUS_POPUP.ACTIVATE: {
      return {
        ...state,
        bonus: action.payload.bonus,
        location: action.payload.location,
      }
    }

    case BONUS_POPUP.DEACTIVATE: {
      return {
        ...state,
        bonus: null,
        location: null,
      }
    }

    default: {
      return state
    }
  }
}
