const { Connector } = require("@netmanagement/arcade-connector")

import { ARCADE, Conditions, Meta } from "./types"
import { initialState } from "./reducer"

import Game from "~/models/game"
import Provider from "~/models/provider"

const connector = new Connector()

connector.setConfiguration({
  ARCADE_API_PORT: process.env.GATSBY_ARCADE_API_PORT!,
  ARCADE_CLIENT_ID: process.env.GATSBY_ARCADE_CLIENT_ID!,
  ARCADE_CLIENT_SECRET: process.env.GATSBY_ARCADE_CLIENT_SECRET!,
  ARCADE_SERVER: process.env.GATSBY_ARCADE_SERVER!,
  ARCADE_API_PATH: process.env.GATSBY_ARCADE_API_PATH!,
})

export function load(action: string, conditions?: Conditions) {
  return {
    type: ARCADE.LOAD,
    payload: { action, conditions },
  }
}

export function loaded(games: Game[], meta: Meta) {
  return {
    type: ARCADE.LOADED,
    payload: {
      games,
      meta,
    },
  }
}

export function activate(game: Game) {
  return {
    type: ARCADE.ACTIVATE,
    payload: { game },
  }
}

export function deactivate() {
  return {
    type: ARCADE.DEACTIVATE,
    payload: {},
  }
}

export function handleLoad() {
  return async function (dispatch: any) {
    const action = "GetGames"

    dispatch(load("GetGames"))

    try {
      const response = (await connector.invokeArcadeAction(action, {
        ...initialState.conditions,
        pageSize: 12,
      })) as any

      const games = await Promise.all(
        response.results.map(async (game: any) => {
          const response = (await connector.invokeArcadeAction(
            "GetProviderById",
            {
              id: game.providerId,
            }
          )) as any

          game.provider = new Provider(response)

          return game
        })
      )

      dispatch(
        loaded(
          games.map((game: any) => new Game(game)),
          {
            pageNumber: response.pageNumber,
            pageSize: response.pageSize,
            pagesCount: response.pagesCount,
            rowsFound: response.rowsFound,
          }
        )
      )
    } catch (error) {
      dispatch(
        loaded([], {
          pageNumber: 0,
          pageSize: 12,
          pagesCount: 0,
          rowsFound: 0,
        })
      )
    }
  }
}

export function handlePagination(
  action: string,
  conditions: Conditions,
  page: number
) {
  return async function (dispatch: any) {
    dispatch(load(action, conditions))

    try {
      const response = (await connector.invokeArcadeAction(action, {
        ...conditions,
        pageNumber: page,
        pageSize: 12,
      })) as any

      const games = await Promise.all(
        response.results.map(async (game: any) => {
          const response = (await connector.invokeArcadeAction(
            "GetProviderById",
            {
              id: game.providerId,
            }
          )) as any

          game.provider = new Provider(response)

          return game
        })
      )

      dispatch(
        loaded(
          games.map((game: any) => new Game(game)),
          {
            pageNumber: response.pageNumber,
            pageSize: response.pageSize,
            pagesCount: response.pagesCount,
            rowsFound: response.rowsFound,
          }
        )
      )
    } catch (e: any) {
      dispatch(
        loaded([], {
          pageNumber: 0,
          pageSize: 12,
          pagesCount: 0,
          rowsFound: 0,
        })
      )
    }
  }
}

export function handleFilter(conditions: Conditions) {
  return async function (dispatch: any) {
    let action = "GetGamesByTypesAndProvider"

    dispatch(load(action, conditions))

    try {
      const response = (await connector.invokeArcadeAction(action, {
        ...conditions,
        pageSize: 12,
      })) as any

      const games = await Promise.all(
        response.results.map(async (game: any) => {
          const response = (await connector.invokeArcadeAction(
            "GetProviderById",
            {
              id: game.providerId,
            }
          )) as any

          game.provider = new Provider(response)

          return game
        })
      )

      dispatch(
        loaded(
          games.map((game: any) => new Game(game)),
          {
            pageNumber: response.pageNumber,
            pageSize: response.pageSize,
            pagesCount: response.pagesCount,
            rowsFound: response.rowsFound,
          }
        )
      )
    } catch (error) {
      dispatch(
        loaded([], {
          pageNumber: 0,
          pageSize: 12,
          pagesCount: 0,
          rowsFound: 0,
        })
      )
    }
  }
}

export function handleSearch(conditions: Conditions) {
  return async function (dispatch: any) {
    let action = "GetGamesByName"

    dispatch(load(action, conditions))

    try {
      const response = (await connector.invokeArcadeAction(action, {
        ...conditions,
        pageSize: 12,
      })) as any

      const games = await Promise.all(
        response.results.map(async (game: any) => {
          const response = (await connector.invokeArcadeAction(
            "GetProviderById",
            {
              id: game.providerId,
              pageSize: 12,
            }
          )) as any

          game.provider = new Provider(response)

          return game
        })
      )

      dispatch(
        loaded(
          games.map((game: any) => new Game(game)),
          {
            pageNumber: response.pageNumber,
            pageSize: response.pageSize,
            pagesCount: response.pagesCount,
            rowsFound: response.rowsFound,
          }
        )
      )
    } catch (error) {
      dispatch(
        loaded([], {
          pageNumber: 0,
          pageSize: 12,
          pagesCount: 0,
          rowsFound: 0,
        })
      )
    }
  }
}

export function handleActivation(game: Game) {
  return activate(game)
}

export function handleDeactivation() {
  return deactivate()
}
