import Bonus from "~/models/bonus"

export interface BonusPopup {
  bonus: Bonus | null
  location: number[] | null
}

export enum BONUS_POPUP {
  ACTIVATE = "BONUS_POPUP_ACTIVATE",
  DEACTIVATE = "BONUS_POPUP_DEACTIVATE",
}
