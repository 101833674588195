import arcadeReducer from "./arcade"
import bonusPopupReducer from "./bonus-popup"

import { logger } from "./middlewares"

interface State {
  arcade: any
  bonusPopup: any
}

export const initialState: State = {
  arcade: arcadeReducer.initialState,
  bonusPopup: bonusPopupReducer.initialState,
}

export default function mainReducer(state: State, action: object) {
  // Receiving previous state here
  const { arcade, bonusPopup } = state

  // Receiving current state here
  const currentState = {
    arcade: arcadeReducer.reducer(arcade, action),
    bonusPopup: bonusPopupReducer.reducer(bonusPopup, action),
  }

  // Middlewares
  if (process.env.GATSBY_ACTIVE_ENV == "development") {
    logger(action, state, currentState)
  }

  return currentState
}
