import { ArcadeProvider, ArcadeAsset } from "../../types/graphql-types"

export default class Provider {
  id: string
  arcadeId: number
  name: string
  normalisedName: string
  url: string
  gameTypes: string[]
  images: ArcadeAsset[]

  constructor(provider: ArcadeProvider) {
    this.id = provider.id
    this.arcadeId = provider.arcadeId || 0
    this.name = provider.name || ""
    this.normalisedName = provider.normalisedName || ""
    this.url = provider.url || ""
    this.gameTypes = provider.gameTypes as string[]
    this.images = (provider.images as any) || []
  }
}
